<template>
  <div class="container mt-3">
    <div class="row">
      <div class="col-md-3">
        <navigation></navigation>
      </div>
      <div class="col-md-9">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import Navigation from "../../components/Account/Navigation.vue";
export default {
  components: { Navigation },
  name: "account.index",
};
</script>
