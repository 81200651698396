<template>
  <div class="d-flex flex-column flex-shrink-0 p-3 bg-light">
    <ul class="nav nav-pills flex-column mb-auto">
      <li class="nav-item">
        <router-link
          :to="`/account`"
          class="nav-link link-dark"
          aria-current="page"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon me-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <circle cx="12" cy="7" r="4"></circle>
            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
          </svg>
          Account Information
        </router-link>
      </li>
      <li class="border-top my-3"></li>
      <li class="nav-item">
        <router-link :to="`/account/billing`" class="nav-link link-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon me-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <rect x="3" y="5" width="18" height="14" rx="3"></rect>
            <line x1="3" y1="10" x2="21" y2="10"></line>
            <line x1="7" y1="15" x2="7.01" y2="15"></line>
            <line x1="11" y1="15" x2="13" y2="15"></line>
          </svg>
          Billing Information
        </router-link>
      </li>
      <li class="nav-item">
        <router-link :to="`/account/plan`" class="nav-link link-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon me-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <path
              d="M16.7 8a3 3 0 0 0 -2.7 -2h-4a3 3 0 0 0 0 6h4a3 3 0 0 1 0 6h-4a3 3 0 0 1 -2.7 -2"
            ></path>
            <path d="M12 3v3m0 12v3"></path>
          </svg>
          Subscription
        </router-link>
      </li>
      <li class="nav-item" v-if="showBillingHistory">
        <router-link :to="`/account/invoices`" class="nav-link link-dark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="icon me-2"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <polyline points="12 8 12 12 14 14"></polyline>
            <path d="M3.05 11a9 9 0 1 1 .5 4m-.5 5v-5h5"></path>
          </svg>
          Billing History
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import BillingService from "../../services/BillingService";

export default {
  name: "account.navigation",
  data() {
    return {
      insertionOrders: [],
    };
  },
  computed: {
    showBillingHistory() {
      if (this.insertionOrders.length == 0) return false;

      return (
        this.insertionOrders.filter(
          (insertionOrder) =>
            insertionOrder.insertionOrder.installmentquantity == 1 &&
            insertionOrder.insertionOrder.installmentstatus != "n"
        ).length > 0
      );
    },
  },
  methods: {
    async loadInsertionOrders() {
      this.insertionOrders = await BillingService.getCurrentInsertionOrders(
        this.$auth.activeCompany
      );
    },
  },
  mounted() {
    this.loadInsertionOrders();
  },
};
</script>
